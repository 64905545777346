@import url('https://fonts.googleapis.com/css?family=Great+Vibes|Muli:300,400,500,600,700&display=swap');

h1,
h2,
h3 {
  font-family: 'Great Vibes', cursive;
}
body {
  font-family: 'Muli', sans-serif;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

ul {
  list-style: none;
  margin-bottom: 0 !important;
}
a {
  text-decoration: none;
  transition: all 0.3s;
}
a:hover {
  text-decoration: none !important;
}
img {
  width: 100%;
}
.section-padding {
  padding: 120px 0;
}

.App {
  text-align: left;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 767px) {
  .section-padding {
    padding: 80px 0;
  }
}

.fixed-navbar {
  position: relative;
  z-index: 5555;
}
.fixed-navbar.active .header,
.fixed-navbar.active .header-style-2,
.fixed-navbar.active .header-style-3 {
  position: fixed;
  z-index: 555;
  top: 0;
  opacity: 1;
  left: 0;
  width: 100%;
  border: none;
}
.fixed-navbar.active .header-style-2,
.fixed-navbar.active .header-style-3 {
  background: #231b0e;
}

.pb-70 {
  padding-bottom: 90px !important;
}

.fixed-navbar.active .header ul li a:hover {
  color: #fff;
}

.fixed-navbar.active .header ul li ul li a:hover {
  color: #1e7b67;
}

.fixed-navbar.active .header .responsivemenu li a:hover {
  color: #1e7b67;
}
