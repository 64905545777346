/*
  	Flaticon icon font: Flaticon
  	Creation date: 05/11/2018 16:13
  	*/

@font-face {
  font-family: 'Flaticon';
  src: url('../fonts/Flaticon.eot');
  src: url('../fonts/Flaticon.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Flaticon.woff') format('woff'), url('../fonts/Flaticon.ttf') format('truetype'),
    url('../fonts/Flaticon.svg#Flaticon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: 'Flaticon';
    src: url('../fonts/Flaticon.svg#Flaticon') format('svg');
  }
}

[class^='flaticon-']:before,
[class*=' flaticon-']:before,
[class^='flaticon-']:after,
[class*=' flaticon-']:after {
  font-family: Flaticon;
  font-size: 60px;
  font-style: normal;
}

.flaticon-wedding-ring:before {
  content: '\f100';
}
.flaticon-wedding-ring-1:before {
  content: '\f101';
}
.flaticon-wedding:before {
  content: '\f102';
}
.flaticon-bird:before {
  content: '\f103';
}
.flaticon-birds-in-love:before {
  content: '\f104';
}
.flaticon-like:before {
  content: '\f105';
}
.flaticon-chat:before {
  content: '\f106';
}
.flaticon-message:before {
  content: '\f107';
}
.flaticon-heart:before {
  content: '\f108';
}
.flaticon-heart-1:before {
  content: '\f109';
}
.flaticon-heart-2:before {
  content: '\f10a';
}
