audio {
  visibility: hidden;
}

.music-btn {
  position: fixed;
  top: 16px;
  right: 16px;
  z-index: 500;
  width: 101px;
  height: 36px;
  outline: none;
  padding: 0;
  margin: 0;
  background: hsla(0, 0%, 57.6%, 0.3);
  color: #fff;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border-radius: 26px;
  cursor: pointer;
}

.music-btn:focus {
  outline: none;
}

.music-btn img {
  display: flex;
}

.music-btn span:nth-child(2) {
  margin-left: 10px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13.3333px;
}
