.rsvp-area {
  background: #5f9a8d;
  padding: 100px 0;
  position: relative;
  background-attachment: fixed;
}

.rsvp-area:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(#163d36, #649e93);
  opacity: 0.8;
}

.rsvp-wrap {
  background: rgba(255, 255, 255, 0.9);
  padding: 70px;
}
.rsvp-wrap .section-title h2 {
  margin-bottom: 0;
  padding-bottom: 0px;
}

.rsvp-wrap .section-title h2:before {
  display: none;
}
.rsvp-wrap .section-title {
  margin-bottom: 30px;
}

.rsvp-wrap .note {
  font-size: 14px;
  font-style: italic;
}

.contact-form input {
  width: 100%;
  height: 45px;
  margin-top: 20px;
  padding-left: 10px;
  background: #fff;
  opacity: 1;
  border: none;
  border-radius: 5px;
}

.form-control {
  margin-top: 20px;
  font-size: 16px;
  background: #fff;
}

.contact-form p {
  color: red;
  font-size: 14px;
}
.section-title p {
  padding-top: 10px;
  color: #5a5858;
}

.contact-form button {
  background: none;
  border: none;
  padding: 5px 15px 8px;
  background: #649e93;
  color: #fff;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
}

.guest-2 button#submit {
  background: #9a8c40;
}
.contact-form button:hover {
  background: #3f7267;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */

  color: #666;
  font-size: 14px;
}
::-moz-placeholder {
  /* Firefox 19+ */

  color: #666;
  font-size: 14px;
}
:-ms-input-placeholder {
  /* IE 10+ */

  color: #666;
  font-size: 14px;
}
:-moz-placeholder {
  /* Firefox 18- */

  color: #666;
  font-size: 14px;
}

.rsvp-form-url {
  display: inline-block;
  background: #649e93;
  color: white;
  padding: 10px 38px;
  font-size: 18px;
  transition: all 0.3s;
  font-weight: 600;
  border-radius: 40px;
}

.rsvp-form-url:hover {
  background: white;
  color: #649e93;
}

.rsvp-form-url:active,
.rsvp-form-url:focus {
  background: #649e93;
  color: white;
}

.thank-you-message {
  margin-top: 2.5rem;
  margin-bottom: 0 !important;
}
