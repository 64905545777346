.Gallery-section {
  background: rgba(178, 201, 211, 0.1);
}
.gallery-img {
  cursor: pointer;
  margin-bottom: 30px;
  overflow: hidden;
}

.image-box {
  width: 100%;
  margin: auto;
  text-align: center;
  position: relative;
  width: auto;
  max-width: 600px; /* Lebar maksimal gambar */
  margin: 20px auto 0px;
  padding: 6px 8px 10px 8px;
  display: inline-block;
  -webkit-box-shadow: 4px 4px 8px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 4px 4px 8px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 4px 4px 8px -4px rgba(0, 0, 0, 0.75);
  background-color: #f5fbfb !important;
  -webkit-transform: rotate(-1deg);
  -moz-transform: rotate(-1deg);
  -o-transform: rotate(-1deg);
  -ms-transform: rotate(-1deg);
  transform: rotate(-1deg);
  -webkit-backface-visibility: hidden;
}

.image-box:before {
  content: '';
  display: block;
  position: absolute;
  left: 5px;
  top: -10px;
  width: 75px;
  height: 25px;
  z-index: 1;
  background-color: rgba(222, 220, 198, 0.7);
  -webkit-transform: rotate(-12deg);
  -moz-transform: rotate(-12deg);
  -o-transform: rotate(-12deg);
  -ms-transform: rotate(-12deg);
}

.image-box img {
  object-fit: contain;
  max-width: 480px;
  height: auto;
  background: #f5fbfb;
}

.gallery-img img {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.gallery-img:hover img {
  -webkit-transform: scale(1.2) rotate(5deg);
  -ms-transform: scale(1.2) rotate(5deg);
  transform: scale(1.2) rotate(5deg);
}

.gallery-img:hover .image-box img {
  transform: none;
}

.gallery-wrapper {
  display: flex;
  justify-content: center;
}
